import React from 'react';

const CareersEasyOnboarding = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9199 26.9285C10.2179 26.9213 8.58813 26.2331 7.3872 25.015C6.18626 23.7968 5.51195 22.1479 5.51196 20.4289V15.273C5.51196 13.5564 6.18715 11.9101 7.38885 10.6963C8.59056 9.4825 10.2204 8.80078 11.9199 8.80078C13.6193 8.80078 15.2492 9.4825 16.4509 10.6963C17.6526 11.9101 18.3277 13.5564 18.3277 15.273V20.4289C18.3313 21.2811 18.1682 22.1255 17.8478 22.9139C17.5274 23.7023 17.0559 24.4191 16.4605 25.023C15.8651 25.6269 15.1576 26.1061 14.3784 26.4331C13.5992 26.76 12.7637 26.9285 11.9199 26.9285ZM11.9199 10.172C10.5852 10.1793 9.30753 10.7197 8.36629 11.6755C7.42504 12.6313 6.89672 13.9249 6.89674 15.273V20.4289C6.84586 21.1283 6.93838 21.831 7.16844 22.4928C7.3985 23.1545 7.7612 23.761 8.23387 24.2748C8.70655 24.7886 9.27899 25.1984 9.91554 25.4786C10.5521 25.7589 11.2391 25.9034 11.9335 25.9034C12.6279 25.9034 13.3148 25.7589 13.9513 25.4786C14.5879 25.1984 15.1604 24.7886 15.6331 24.2748C16.1057 23.761 16.4684 23.1545 16.6985 22.4928C16.9285 21.831 17.021 21.1283 16.9701 20.4289V15.273C16.9701 13.9201 16.4381 12.6227 15.491 11.6661C14.5438 10.7095 13.2593 10.172 11.9199 10.172Z"
      fill="#475467"
    />
    <path
      d="M18.9793 43.1884H4.5616C3.3518 43.1884 2.19151 42.703 1.33606 41.839C0.480602 40.9749 0 39.8032 0 38.5813V33.0963C0.0667506 32.4657 0.275126 31.8587 0.609196 31.3217C0.943267 30.7846 1.39428 30.3317 1.92785 29.9973C3.01241 29.1542 4.24774 28.5302 5.56621 28.1597L10.0463 26.8983C10.2227 26.851 10.4105 26.8763 10.5684 26.9689C10.7262 27.0615 10.8412 27.2139 10.888 27.3921C10.9348 27.5703 10.9096 27.7599 10.818 27.9193C10.7263 28.0788 10.5757 28.1948 10.3993 28.2421L5.94629 29.5035C4.79065 29.8083 3.70939 30.3499 2.76956 31.0943C2.41441 31.3134 2.10801 31.6041 1.86968 31.9487C1.63136 32.2933 1.46625 32.6841 1.38478 33.0963V38.5813C1.38119 39.0049 1.46074 39.4251 1.61878 39.8176C1.77682 40.2101 2.01027 40.5669 2.30562 40.8678C2.60096 41.1686 2.95235 41.4075 3.33956 41.5705C3.72676 41.7334 4.14212 41.8172 4.5616 41.8172H18.9793C19.829 41.8172 20.644 41.4763 21.2448 40.8695C21.8457 40.2626 22.1832 39.4395 22.1832 38.5813V33.0963C22.1832 32.3558 21.4229 31.588 20.7984 31.0943C19.8586 30.3499 18.7773 29.8083 17.6217 29.5035L13.2774 28.2695C13.1045 28.2186 12.9588 28.1005 12.8723 27.9411C12.7857 27.7817 12.7653 27.5937 12.8157 27.4192C12.8665 27.2435 12.9822 27.0946 13.1389 27.0027C13.2956 26.9108 13.4812 26.8832 13.6574 26.9257L17.9746 28.1597C19.3036 28.5243 20.5489 29.1487 21.6401 29.9973C22.1737 30.3317 22.6247 30.7846 22.9588 31.3217C23.2929 31.8587 23.5012 32.4657 23.568 33.0963V38.5813C23.5608 39.8057 23.0741 40.9774 22.2144 41.8406C21.3546 42.7039 20.1916 43.1885 18.9793 43.1884Z"
      fill="#475467"
    />
    <path
      d="M10.2092 28.4071C10.0219 28.4071 9.84239 28.3319 9.71 28.1982C9.5776 28.0645 9.50317 27.8831 9.50317 27.694V26.213C9.5102 26.0286 9.58772 25.8544 9.71936 25.7265C9.851 25.5987 10.0265 25.5272 10.2092 25.5273C10.3892 25.5273 10.5619 25.5996 10.6892 25.7282C10.8165 25.8568 10.888 26.0311 10.888 26.213V27.694C10.8881 27.8785 10.8175 28.0555 10.6909 28.1885C10.5643 28.3214 10.3916 28.4 10.2092 28.4071Z"
      fill="#475467"
    />
    <path
      d="M13.4674 28.4342C13.2873 28.4342 13.1147 28.3622 12.9874 28.2337C12.8601 28.1051 12.7886 27.9304 12.7886 27.7486V26.213C12.7886 26.0311 12.8601 25.8568 12.9874 25.7282C13.1147 25.5996 13.2873 25.5273 13.4674 25.5273C13.6474 25.5273 13.82 25.5996 13.9473 25.7282C14.0746 25.8568 14.1462 26.0311 14.1462 26.213V27.7486C14.1462 27.9304 14.0746 28.1051 13.9473 28.2337C13.82 28.3622 13.6474 28.4342 13.4674 28.4342Z"
      fill="#475467"
    />
    <path
      d="M11.7838 34.4145C10.5581 34.2305 9.45113 33.5729 8.69634 32.5802C7.94155 31.5876 7.59803 30.3378 7.73822 29.0942C7.73822 28.7651 7.73822 28.4357 7.73822 28.1066C7.74757 28.0144 7.77614 27.9255 7.82199 27.8452C7.86784 27.7649 7.92995 27.695 8.00428 27.6406C8.07862 27.5863 8.16352 27.5484 8.25337 27.5295C8.34321 27.5105 8.43598 27.511 8.52565 27.5308C8.61416 27.5407 8.69971 27.5689 8.77705 27.6135C8.85439 27.6581 8.92182 27.7183 8.97525 27.7903C9.02869 27.8622 9.06696 27.9445 9.0877 28.032C9.10843 28.1195 9.1112 28.2102 9.09582 28.2988C9.08139 28.5637 9.08139 28.8293 9.09582 29.0942C9.09582 31.2334 10.3177 33.0432 11.811 33.0432C13.3044 33.0432 14.5262 31.2334 14.5262 29.0942C14.5541 28.8299 14.5541 28.5632 14.5262 28.2988C14.5108 28.2081 14.5145 28.1152 14.5368 28.026C14.5592 27.9368 14.5998 27.8533 14.6561 27.7809C14.7123 27.7085 14.7831 27.6489 14.8636 27.6058C14.9441 27.5628 15.0327 27.5372 15.1236 27.5308C15.2997 27.5072 15.478 27.5544 15.6202 27.6621C15.7623 27.7697 15.8569 27.9292 15.8838 28.1066C15.9106 28.4352 15.9106 28.7657 15.8838 29.0942C16.0229 30.3461 15.672 31.6033 14.906 32.5973C14.1399 33.5914 13.0194 34.2434 11.7838 34.4145Z"
      fill="#475467"
    />
    <path
      d="M14.0377 16.0127C13.4483 16.0247 12.8637 15.9029 12.3271 15.6562C11.7834 15.3735 11.2956 14.9923 10.8881 14.5317C10.656 14.2024 10.3214 13.961 9.93774 13.8461C9.12318 13.6541 8.00995 14.285 6.70665 15.7111C6.64333 15.7774 6.56739 15.83 6.48342 15.8661C6.39945 15.9021 6.30918 15.9207 6.21793 15.9207C6.12669 15.9207 6.03642 15.9021 5.95245 15.8661C5.86847 15.83 5.79254 15.7774 5.72922 15.7111C5.60484 15.5829 5.53516 15.4105 5.53516 15.231C5.53516 15.0516 5.60484 14.8791 5.72922 14.751C7.41264 12.8861 8.90595 12.0086 10.2636 12.4748C10.913 12.6472 11.4924 13.0214 11.9199 13.5444C12.1906 13.8901 12.5336 14.1712 12.9245 14.3673C13.6576 14.7238 14.8251 14.9156 17.2416 13.517C17.32 13.4735 17.4061 13.446 17.495 13.4363C17.5839 13.4266 17.6739 13.4349 17.7597 13.4604C17.8455 13.4859 17.9254 13.5281 17.9949 13.5849C18.0645 13.6417 18.1222 13.7118 18.1648 13.7911C18.2539 13.9478 18.2782 14.1335 18.2324 14.308C18.1867 14.4826 18.0745 14.6319 17.9204 14.7235C16.7557 15.4795 15.4194 15.9233 14.0377 16.0127Z"
      fill="#475467"
    />
    <path
      d="M25.6586 22.1003C23.9592 22.1003 22.3293 21.4182 21.1276 20.2045C19.9259 18.9907 19.2507 17.3446 19.2507 15.6281V10.4722C19.2507 8.75566 19.9259 7.10928 21.1276 5.8955C22.3293 4.68172 23.9592 4 25.6586 4C27.3581 4 28.988 4.68172 30.1897 5.8955C31.3914 7.10928 32.0665 8.75566 32.0665 10.4722V15.6281C32.0665 17.3446 31.3914 18.9907 30.1897 20.2045C28.988 21.4182 27.3581 22.1003 25.6586 22.1003ZM25.6586 5.37124C24.3192 5.37124 23.0347 5.90872 22.0876 6.86534C21.1405 7.82197 20.6083 9.11934 20.6083 10.4722V15.6281C20.6083 16.981 21.1405 18.2783 22.0876 19.235C23.0347 20.1916 24.3192 20.7291 25.6586 20.7291C26.9981 20.7291 28.2826 20.1916 29.2297 19.235C30.1768 18.2783 30.7089 16.981 30.7089 15.6281V10.4722C30.7197 9.79544 30.5971 9.1231 30.3481 8.49469C30.0991 7.86628 29.7288 7.2942 29.2588 6.81178C28.7888 6.32936 28.2284 5.9464 27.6103 5.68492C26.9922 5.42344 26.3288 5.2888 25.6586 5.28888V5.37124Z"
      fill="#475467"
    />
    <path
      d="M17.6489 25.5544C17.4998 25.5493 17.3565 25.4947 17.2412 25.3991C17.1259 25.3035 17.045 25.1722 17.011 25.0255C16.977 24.8788 16.9918 24.7246 17.0531 24.5873C17.1145 24.4499 17.2189 24.337 17.3503 24.2655C17.9736 23.9414 18.6288 23.6839 19.3052 23.4976L23.7581 22.2361C23.8454 22.2072 23.9379 22.197 24.0293 22.2067C24.1207 22.2164 24.2091 22.2454 24.2886 22.292C24.3681 22.3387 24.437 22.4018 24.4906 22.4772C24.5442 22.5526 24.5814 22.6386 24.5998 22.7296C24.6502 22.9042 24.6299 23.0917 24.5434 23.2512C24.4568 23.4106 24.3111 23.529 24.1383 23.5799L19.6582 24.8414C19.0679 25.0031 18.4949 25.2236 17.9476 25.4995C17.8552 25.5461 17.7516 25.5652 17.6489 25.5544Z"
      fill="#475467"
    />
    <path
      d="M26.5002 38.3908H24.9525C24.7725 38.3908 24.5998 38.3188 24.4725 38.1902C24.3452 38.0617 24.2737 37.887 24.2737 37.7052C24.2737 37.5233 24.3452 37.349 24.4725 37.2204C24.5998 37.0918 24.7725 37.0195 24.9525 37.0195H26.5002C26.6802 37.0195 26.8529 37.0918 26.9802 37.2204C27.1075 37.349 27.179 37.5233 27.179 37.7052C27.179 37.887 27.1075 38.0617 26.9802 38.1902C26.8529 38.3188 26.6802 38.3908 26.5002 38.3908Z"
      fill="#475467"
    />
    <path
      d="M33.3157 25.5564H33.0169C32.4751 25.297 31.9118 25.086 31.3335 24.9257L27.0164 23.6914C26.9283 23.6692 26.8456 23.6293 26.7731 23.5742C26.7006 23.5191 26.6398 23.45 26.5942 23.3707C26.5486 23.2914 26.5193 23.2038 26.5078 23.1129C26.4964 23.022 26.5032 22.9297 26.5277 22.8414C26.5784 22.6658 26.6941 22.5165 26.8508 22.4246C27.0075 22.3327 27.1931 22.3051 27.3694 22.3476L31.7137 23.5819C32.3693 23.7667 33.0057 24.0143 33.6143 24.3221C33.6975 24.3603 33.7723 24.4151 33.8341 24.4832C33.8959 24.5512 33.9434 24.6314 33.9738 24.7185C34.0042 24.8056 34.017 24.8978 34.0112 24.99C34.0054 25.0822 33.9812 25.1723 33.9401 25.2548C33.8704 25.355 33.7764 25.4353 33.667 25.4882C33.5576 25.541 33.4366 25.5646 33.3157 25.5564Z"
      fill="#475467"
    />
    <path
      d="M23.9483 23.6102C23.7658 23.6031 23.5932 23.5246 23.4666 23.3916C23.34 23.2586 23.2694 23.0816 23.2695 22.8971V21.4161C23.2695 21.2343 23.341 21.0599 23.4683 20.9313C23.5956 20.8028 23.7683 20.7305 23.9483 20.7305C24.1284 20.7305 24.301 20.8028 24.4283 20.9313C24.5556 21.0599 24.6271 21.2343 24.6271 21.4161V22.8971C24.6273 23.0816 24.5567 23.2586 24.4301 23.3916C24.3035 23.5246 24.1308 23.6031 23.9483 23.6102Z"
      fill="#475467"
    />
    <path
      d="M27.1793 23.6349C26.9993 23.6349 26.8266 23.5629 26.6993 23.4344C26.572 23.3058 26.5005 23.1311 26.5005 22.9493V21.4136C26.5324 21.2519 26.6188 21.1062 26.745 21.0015C26.8713 20.8969 27.0296 20.8398 27.1929 20.8398C27.3562 20.8398 27.5144 20.8969 27.6407 21.0015C27.7669 21.1062 27.8534 21.2519 27.8853 21.4136V22.9493C27.8782 23.1336 27.8007 23.3082 27.6691 23.436C27.5374 23.5639 27.3619 23.635 27.1793 23.6349Z"
      fill="#475467"
    />
    <path
      d="M25.4955 29.615C24.2748 29.4247 23.1744 28.7643 22.4252 27.7724C21.676 26.7805 21.3362 25.5342 21.477 24.2948C21.4499 23.9662 21.4499 23.6357 21.477 23.3072C21.4894 23.2171 21.5194 23.1308 21.565 23.0524C21.6107 22.9741 21.6712 22.9053 21.743 22.8505C21.8149 22.7958 21.8967 22.7559 21.9838 22.733C22.071 22.7102 22.1617 22.705 22.2508 22.7176C22.3399 22.7302 22.4258 22.7606 22.5034 22.8067C22.5809 22.8528 22.6488 22.9139 22.703 22.9865C22.7572 23.059 22.7967 23.1415 22.8193 23.2295C22.8419 23.3175 22.847 23.4093 22.8346 23.4993C22.8201 23.7642 22.8201 24.0299 22.8346 24.2948C22.8346 26.4339 24.0564 28.2438 25.5498 28.2438C27.0431 28.2438 28.265 26.4339 28.265 24.2948C28.2794 24.0299 28.2794 23.7642 28.265 23.4993C28.2398 23.3175 28.2871 23.133 28.3966 22.9865C28.5061 22.8399 28.6687 22.7431 28.8487 22.7176C29.0287 22.6922 29.2114 22.74 29.3566 22.8505C29.5017 22.9611 29.5974 23.1253 29.6226 23.3072C29.6504 23.6357 29.6504 23.9663 29.6226 24.2948C29.7612 25.5508 29.4066 26.8114 28.6349 27.8062C27.8633 28.801 26.736 29.4505 25.4955 29.615Z"
      fill="#475467"
    />
    <path
      d="M27.7493 11.2137C27.1604 11.2204 26.5769 11.0988 26.0387 10.8572C25.5026 10.5749 25.0237 10.1934 24.6268 9.73269C24.3842 9.41416 24.0535 9.17553 23.6765 9.04707C22.8348 8.8551 21.7215 9.48602 20.4454 10.9121C20.3821 10.9785 20.3062 11.0311 20.2222 11.0671C20.1383 11.1032 20.0479 11.1217 19.9567 11.1217C19.8654 11.1217 19.7752 11.1032 19.6912 11.0671C19.6072 11.0311 19.5313 10.9785 19.468 10.9121C19.403 10.8505 19.3513 10.776 19.3159 10.6935C19.2805 10.611 19.2622 10.522 19.2622 10.432C19.2622 10.3421 19.2805 10.2531 19.3159 10.1706C19.3513 10.088 19.403 10.0135 19.468 9.95197C21.1242 8.11451 22.6447 7.20961 24.0023 7.67583C24.6473 7.84092 25.2202 8.21704 25.6314 8.74544C25.908 9.08527 26.2497 9.36526 26.636 9.56832C27.3963 9.89741 28.5367 10.1167 30.9533 8.71799C31.1157 8.62898 31.3062 8.60779 31.4838 8.65907C31.6615 8.71034 31.8122 8.82993 31.9035 8.99217C31.9465 9.07135 31.9736 9.15848 31.9832 9.24827C31.9929 9.33806 31.9849 9.42878 31.9596 9.51542C31.9344 9.60207 31.8925 9.68288 31.8362 9.75311C31.78 9.82334 31.7107 9.88146 31.6321 9.92452C30.4727 10.6919 29.1333 11.1368 27.7493 11.2137Z"
      fill="#475467"
    />
    <path
      d="M37.1981 44.9999C35.2595 44.9999 33.3644 44.4193 31.7525 43.3314C30.1406 42.2435 28.8843 40.6973 28.1424 38.8883C27.4005 37.0792 27.2064 35.0888 27.5846 33.1683C27.9628 31.2478 28.8964 29.4837 30.2672 28.0991C31.638 26.7145 33.3845 25.7715 35.2859 25.3894C37.1872 25.0074 39.1581 25.2035 40.9491 25.9529C42.7402 26.7022 44.2711 27.9711 45.3481 29.5992C46.4252 31.2273 47 33.1415 47 35.0996C47 37.7253 45.9673 40.2434 44.1291 42.1001C42.2909 43.9568 39.7978 44.9999 37.1981 44.9999ZM37.1981 26.598C35.5269 26.598 33.8932 27.0988 32.504 28.0372C31.1147 28.9756 30.0324 30.3093 29.3941 31.8694C28.7558 33.4294 28.5901 35.146 28.9181 36.8013C29.2462 38.4565 30.0531 39.976 31.2368 41.1677C32.4204 42.3595 33.9276 43.1694 35.5674 43.4954C37.2072 43.8214 38.906 43.6487 40.4485 42.999C41.991 42.3492 43.3079 41.2519 44.2325 39.8457C45.1571 38.4395 45.6477 36.7877 45.6424 35.0996C45.6424 33.9808 45.4238 32.8732 44.9991 31.8399C44.5744 30.8066 43.952 29.8681 43.1675 29.0783C42.383 28.2885 41.4519 27.6627 40.4276 27.2371C39.4032 26.8114 38.3058 26.5944 37.1981 26.598Z"
      fill="#475467"
    />
    <path
      d="M35.7321 40.6377C35.6253 40.6305 35.5213 40.5997 35.4275 40.5476C35.3337 40.4955 35.2523 40.4233 35.1891 40.336L31.9308 35.4543C31.8379 35.2968 31.8086 35.1092 31.849 34.9304C31.8895 34.7517 31.9966 34.5956 32.148 34.4945C32.2205 34.4439 32.3023 34.4085 32.3886 34.3908C32.475 34.373 32.564 34.3731 32.6503 34.3911C32.7366 34.409 32.8183 34.4444 32.8907 34.4952C32.963 34.546 33.0245 34.6109 33.0712 34.6864L35.7864 38.7177L41.2168 31.4779C41.3513 31.3564 41.5252 31.2891 41.7056 31.2891C41.8859 31.2891 42.0599 31.3564 42.1943 31.4779C42.3187 31.606 42.3883 31.7781 42.3883 31.9576C42.3883 32.1371 42.3187 32.3095 42.1943 32.4377L36.1123 40.473C36.0056 40.5664 35.8727 40.6241 35.7321 40.6377Z"
      fill="#475467"
    />
  </svg>
);

export default CareersEasyOnboarding;
